// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-components-about-js": () => import("./../src/pages/components/About.js" /* webpackChunkName: "component---src-pages-components-about-js" */),
  "component---src-pages-components-authors-js": () => import("./../src/pages/components/Authors.js" /* webpackChunkName: "component---src-pages-components-authors-js" */),
  "component---src-pages-components-contribute-js": () => import("./../src/pages/components/Contribute.js" /* webpackChunkName: "component---src-pages-components-contribute-js" */),
  "component---src-pages-components-demo-js": () => import("./../src/pages/components/Demo.js" /* webpackChunkName: "component---src-pages-components-demo-js" */),
  "component---src-pages-components-installation-js": () => import("./../src/pages/components/Installation.js" /* webpackChunkName: "component---src-pages-components-installation-js" */),
  "component---src-pages-components-nav-js": () => import("./../src/pages/components/Nav.js" /* webpackChunkName: "component---src-pages-components-nav-js" */),
  "component---src-pages-components-spacer-js": () => import("./../src/pages/components/Spacer.js" /* webpackChunkName: "component---src-pages-components-spacer-js" */),
  "component---src-pages-components-title-js": () => import("./../src/pages/components/Title.js" /* webpackChunkName: "component---src-pages-components-title-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

